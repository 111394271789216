// App.js

import React, { useState, useEffect } from 'react';
import { FaCog, FaArrowLeft} from 'react-icons/fa'; // 아이콘 가져오기
// import { FaArrowRotateLeft, } from 'react-icons/fa6'; // 아이콘 가져오기
import './App.css';

function App() {
  const [bpm, setBpm] = useState(120);
  const [showSettings, setShowSettings] = useState(false);
  const [newBpm, setNewBpm] = useState(bpm); // 변경된 BPM 값을 저장
  const [currentChord, setCurrentChord] = useState('');
  const [nextChord, setNextChord] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentChord(nextChord);
      setNextChord(generateRandomChord());
    }, (60 / bpm) * 1000 * 4);

    return () => clearInterval(interval);
  }, [bpm, nextChord]);

  function generateRandomChord() {
    const chords = ['C', "C#", 'D', "Eb", 'E', 'F', "F#", 'G', "Ab", 'A', "Bb", 'B']; // 예시 음악 코드, 실제 음악 코드로 변경해야 함
    const randomIndex = Math.floor(Math.random() * chords.length);
    const suffix = ["", "m", "dim", "sus4", "aug", "M7", "m7", "7", "m7b5", "dim7", "mM7", "7sus4", "aug7", 
    // "augM7", 
    "6", "m6"]
    const suffixIndex = Math.floor(Math.random() * suffix.length);
    return chords[randomIndex] + suffix[suffixIndex];
  }

  function handleBpmChange(newBpmValue) {
    setNewBpm(newBpmValue);
  }

  function applyBpmChange() {
    setBpm(newBpm); // 변경된 BPM 값 적용
    setShowSettings(false); // 설정 화면 닫기
  }

  function cancelBpmChange() {
    setNewBpm(bpm); // 이전 BPM 값으로 변경된 값 초기화
    setShowSettings(false); // 설정 화면 닫기
  }

  return (
    <div className="App">
      <header className="App-header">
      <button
          className="settings-btn"
          onClick={() => setShowSettings(!showSettings)}
        >
          {showSettings ? <FaArrowLeft /> : <FaCog />} {/* 아이콘 변경 */}
        </button>
        {showSettings ? (
          <div className="settings">
            <h2 className="bpm">BPM</h2>
            <input
              type="number"
              value={newBpm}
              onChange={(e) => handleBpmChange(e.target.value)}
              maxLength={10}
            />
            {/* 확인 및 취소 버튼 */}
            <div className="btn-group">
              <button onClick={applyBpmChange}>OK</button>
              <button onClick={cancelBpmChange}>Cancel</button>
            </div>
          </div>
        ) : (
          <div className="main-screen">
            {/* <h1>{bpm} BPM</h1> */}
            <h1 className="current-chord">{currentChord}</h1>
            <h2 className="next-chord">{nextChord}</h2>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
